/* --------------------- Fonts ------------------ */
/* ---------------- Raleway font ---------- */
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

/* --------------------------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  user-select: none;
  width: 100%;
  height: 100vh;
  font-family: "Raleway" !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.wrapper {
  /* width: 100%;
  height: 100%; */
  overflow-x: hidden;
  overflow-y: auto;
}

/* ------------- common css ---------------- */

.w_100 {
  width: 100%;
}
.h_100 {
  height: 100%;
}
.w_h_100 {
  width: 100%;
  height: 100%;
}
.d_flex {
  display: flex;
}
.f_col {
  flex-direction: column;
}
.textCenter {
  text-align: center;
}
.startDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.centerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.endDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.spaceBetweenDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spaceEvenlyDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* ------------ custom scroll ------------ */

.customScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
  border-radius: 10px;
}

.customScroll::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.customScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(0 0 0 / 65%)),
    color-stop(0.72, rgb(0 0 0 / 75%)),
    color-stop(0.86, rgb(0 0 0))
  );
}
/* ------------------------------------------ */

.primaryBtn {
  border: none;
  background: #213457;
  /* padding: 1% 3%; */
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 17px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  opacity: 0.8;
}

.secondaryBtn {
  border: none;
  background: #9e9e9e;
  /* padding: 1% 3%; */
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 17px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  opacity: 0.8;
}

.primaryBtn:hover,
.secondaryBtn:hover {
  opacity: 1;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/*//-------------------------- */
.modal-header {
  border-bottom: none !important;
}
.modal-footer {
  border-top: none !important;
}


/* -------------------- loader ------------------ */

.suspense_loader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  border-top: 7px solid #1495d6;
  border-right: 7px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.suspense_loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border-bottom: 7px solid #213456;
  border-left: 7px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

/* ---------------------- */
.formError_text{
  color: red;
  font-size: 13px;
  margin-bottom: 0;
}



/* -------------- spinner loader --------------  */
.loader {
  width: 25px;
  height: 25px;
  object-fit: contain;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.circular-loader {
  width: 25px;
  height: 25px;
  object-fit: contain;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}


@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}