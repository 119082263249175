.brandLogo {
  width: 150px;
}

.navSection .navbar-nav .nav-link.active {
  color: rgb(19 148 214);
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 8px;
}
.navSection.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0.5rem 0.7rem;
  color: #fff !important;
}
.requestDemoBtn {
    background-color: #213457 !important;
    border-radius: 4px;
    color: #fff !important;
    text-decoration: none !important;
    margin: 0 5px !important;
    padding: 0.5rem 0.9rem;
    border: none;
}
.requestDemoBtn:hover {
  background-color: #394e75 !important;
}
.navSection {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: transparent !important;
  transition: background-color 0.3s ease;
}

.navSection.scrolled {
  background-color: #fff !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.navSection.scrolled.navbar-expand-lg .navbar-nav .nav-link{
    color: #000 !important;
}

.sidebarMenuBtn{
  background: none;
  border: none;
  font-size: 30px;
  padding: 0 !important;
  margin: 0 !important;
  color: #fff;
  position: absolute;
  top: 5%;
  right: 0%;
  transform: translate(-5%, 0%);
}

.offcanvasNavMenu .nav-link{
  margin: 5%;
  font-size: 20px
}
.offcanvasNavMenu .nav-link.active{
  color: #1495d6;
}