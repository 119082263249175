p {
  color: #a8a8a8;
  font-size: 17px;
}
.devider {
  border: 0.5px solid #a8a8a8;
  margin: 3% 20%;
}
/* --------------------------- hero section --------------------------- */
.landingPage {
  width: 100%;
  height: 100vh;
  position: relative;
}
.landingPage .video-background video {
  width: 100%;
  height: 100vh;
  z-index: 1;
  object-fit: cover;
}
.landingpage_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.landingpage_content h1 {
  color: #fff;
  font-weight: 800 !important;
}
.landingFeatureDiv {
  padding: 5% 0;
}
.landingFeatureContet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.landingFeatureContet h4 {
  /* font-family: "openSans-bold"; */
  color: #1394d6;
}
.landingFeatureContet p {
  color: #a8a8a8;
}
.landingFeatureImg {
  width: 65%;
  height: 100%;
  object-fit: contain;
}

/* ----------------------------- walk through section ---------------------- */
.walkThroughPage {
  background-image: url(../../public/assets/images/bgImage2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 85vh;
}

.walkThroughPage_Backdrop {
  height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.walkThroughPage_Backdrop h5 {
  color: #fff;
}

.walkThroughPage2 {
  background-color: #f6f6f6;
  padding: 5% 0 2%;
}
.walkThrough_header {
  text-align: center;
}
.walkThrough_header p {
  padding: 0 5%;
}
.walkThrough_header h5 {
  color: #1495d6;
}
.walkThrough_content {
  padding: 5% 0;
  color: #777777;
}
.walkThrough_content_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
}
.walkThrough_content img {
  width: 75%;
  margin: 4% 0 10%;
}
/* -------------------------------------- Feature css -------------------------------- */
.feature_ProcessLevel {
  background-color: #212331;
  padding: 5% 0 7%;
  color: #fff;
  text-align: center;
}
.feature_ProcessLevel_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}
.featureLevel_Count {
  border: 1px solid #fff;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 30px;
  margin: 10px 0;
  cursor: pointer;
  background-color: #212331;
}
.featureLevel_Count:hover {
  background-color: #fff;
  color: #212331;
}
.featureLevel_Head {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
}
.featureLevel_Content {
  color: #fff;
  font-size: 13px;
}

.feature_ProcessLevel_content .featureLevel_List {
  text-align: center;
  padding: 55px 15px 0 15px;
}

.feature_slide_ques p {
  font-size: 16px;
}

.feature_slide_ques2 p {
  font-size: 16px;
  color: #8d8a8a;
  text-align: left;
  margin-bottom: 10px;
}

.feature_slide_ques2 {
  border: 1px solid #a8d5ec;
  border-radius: 7px;
}
.right_slide_ques2_head {
  border-bottom: 1px solid #a8d5ec;
  padding: 10px;
  background-color: #63c8fc4a;
  border-radius: 6px 6px 0 0;
}
.right_slide_ques2_content {
  padding: 10px 10px 10px 25px;
}
.quoteStart {
  margin-top: -15px;
  margin-right: 5px;
  font-size: 25px;
  color: #1496d6;
}
.quoteEnd {
  margin-top: -10px;
  margin-left: 2px;
  rotate: 180deg;
  font-size: 25px;
  color: #1496d6;
}

/* ------- process back after before line ------- */
.feature_ProcessLevel_content .featureLevel_List:first-child::before,
.feature_ProcessLevel_content .featureLevel_List:last-child::after {
  opacity: 0;
  visibility: hidden;
}
.feature_ProcessLevel_content .featureLevel_List::before,
.feature_ProcessLevel_content .featureLevel_List::after {
  background: rgba(255, 255, 255, 0.5);
}
.feature_ProcessLevel_content .featureLevel_List::before {
  left: 0;
}
.feature_ProcessLevel_content .featureLevel_List::after {
  right: 0;
}
.feature_ProcessLevel_content .featureLevel_List::before,
.feature_ProcessLevel_content .featureLevel_List::after {
  content: "";
  height: 1px;
  top: 100px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  width: 50%;
  z-index: -1;
  margin: 0 10%;
}
/* ------------- */

.feature_slideContent {
  padding: 7% 0;
}
.feature_slideContent img {
  width: 75%;
  border-radius: 25px 5px;
}
.feature_slideContent_p {
  padding: 0 15%;
}

.feature_slideContent2 img {
  width: 100%;
  border-radius: 25px 5px;
}
/* -------------------------------- Portfolio css ------------------------------- */
.portfolio_Page {
  text-align: center;
  background-color: #f6f6f6;
  padding: 6% 0 7%;
}
.portfolio_Page h4 {
  color: #1495d6;
}
.portfolio_Page h3 {
  font-weight: 800;
  color: #000;
}
.portfolio_link {
  width: 250px;
  height: 250px;
  position: relative;
}
.portfolio_link a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.portfolio_link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
}
.portfolio_link img:hover .portfolio_link_overlay {
  visibility: visible;
}
.portfolio_link_overlay {
  background-color: #c09cf063;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  visibility: hidden;
}
.portfolio_link:hover .portfolio_link_overlay {
  visibility: visible !important;
  transition: all 0.2s ease-in-out;
}

.portfolio_filter {
  margin: 3% 0;
}
.portfolio_filter button {
  color: #b7b7b7;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  position: relative;
  padding: 5px 1.125rem;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border: 1px solid #b7b7b7;
  margin: 0.5%;
}
.portfolio_filter button.active {
  border: 1px solid #212331;
  color: #fff;
  background: #212331;
}

.slider-container .slick-prev:before,
.slider-container .slick-next:before {
  font-size: 30px;
  color: #213457;
}
.slider-container .slick-prev {
  left: 0px;
  z-index: 10;
}
.slider-container .slick-next {
  right: 0px;
  z-index: 10;
}

/* ----------------------------------- */
.walkThrough_content2 img {
  width: 85%;
  margin: 4% 0 10%;
}

/* --------- blog page css --------- */
.blogPage {
  background-image: url(../../public/assets/images/CraftingSolutions.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 80vh;
}
.walkThrough_header2 {
  height: 80vh !important;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20%;
}

/* ------------- pricing page css ------------ */

.pricing_header h5 {
  color: #1394d6;
}
.pricing_header h2 {
  color: #000;
}
.pricingPage {
  background-color: #f6f6f6;
}
.pricing_content_div {
  border: 1px solid #f6f3f3;
  background: #fff;
  border-radius: 30px;
  padding: 10px;
  min-height: 60vh;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pricing_content_div:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  scale: 1.01;
  background: #212529;
}
.pricing_content_div:hover {
  color: #fff;
}
.pricing_content_div:hover p {
  color: #fff;
}
.pricing_content_div:hover .priceBtn {
  background-color: #ff0303;
}
.pricing_content_div p {
  color: #a8a8a8;
  font-size: 15px;
}
.priceAmount {
  color: #213457;
}
.priceAmount span {
  color: #a8a8a8;
  font-size: 15px;
}
.pricing_content_div:hover .priceAmount {
  color: #fff;
}
.pricing_content_div ul li {
  list-style-type: none;
  padding: 8px 0;
}
.pricing_content_div ul li > span {
  padding-left: 20px;
  font-size: 15px;
}

.pricing_content_div ul li > span::before {
  color: #30e512;
}

.pricing_content_div ul li > span::before {
  content: "\2713";
  color: #30e512;
  margin-right: 8px;
  /* margin-left: -20px; */
  font-size: 20px;
}

.pricing_content_div ul li.not-support {
  color: #9b9b9b;
}
.pricing_content_div ul li.not-support > span {
  /* text-decoration: line-through; */
}

.pricing_content_div ul li.not-support > span::before {
  content: "\00D7";
  color: #ff434b;
  font-size: 30px;
  line-height: 0.6;
  /* text-decoration: line-through; */
}
.pricing_content_div ul li.not-support > span {
  /* text-decoration: line-through; */
}

.priceBtn {
  /* position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, 0); */
  min-width: 60%;
  border-radius: 50px;
}
.priceBtn:hover {
  background-color: #ff0303;
}
.top20 {
  text-align: left;
  padding: 0;
}
/* -- toggle css -- */
.switches-container {
  width: 22rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: #213457;
  line-height: 3rem;
  border-radius: 3rem;
  margin-left: auto;
  margin-right: auto;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: #fff;
}

/* switch highlighters wrapper (sliding left / right) 
  - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 1;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
  border-radius: 3rem;
  background: #fff;
  height: 100%;
}

/* switch box labels
  - default setup
  - toggle afterwards based on radio:checked status 
*/
.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: #213457;
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container
  input:nth-of-type(1):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container
  input:nth-of-type(2):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}

.planName {
  width: fit-content;
  border-image: conic-gradient(
      from 45deg at calc(100% - 1.5em) 50%,
      #0000 25%,
      #ccd0d8 0
    )
    1 fill;
  line-height: 1.5em;
  padding: 6px 70px 6px 50px;
  margin-left: -35px;
}
.planSelectedModal .modal-content {
  overflow: hidden;
}

/* ------------------ contact page css --------------- */
.contactPage {
  background-image: url(../../public/assets/images/contactBg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 80vh;
}
.contact_header {
  color: #fff;
}

.contactForm .contactFormInput_container {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
}
.contactForm .contactFormInput {
  width: 50%;
  padding: 0 2%;
}
.contactForm .contactFormInput label {
  margin-bottom: 5px !important;
  font-size: 15px;
}
.contactForm .contactFormInput input {
  padding: 0.675rem 0.75rem;
}
.contactForm .contactFormInput input::placeholder {
  color: #b9b9b9;
  font-size: 14px;
}
.map-loc2,
.map-loc2 iframe {
  width: 100%;
  height: 100%;
  /* border-radius: 10px; */
}

/* ----------------- request form css --------------- */
.requestForm .requestFormInput_container {
  display: flex;
  flex-wrap: wrap;
}
.requestForm .requestFormInput {
  width: 50%;
  padding: 0 2%;
}

.requestForm .requestFormInput label {
  margin-bottom: 5px !important;
  font-size: 15px;
}
.requestForm .requestFormInput input {
  padding: 0.675rem 0.75rem;
}
.requestForm .requestFormInput input::placeholder {
  color: #b9b9b9;
  font-size: 14px;
}

.requestFormModal .modal-title {
  width: 100%;
  text-align: center;
}

/* ----------------------- responsice css ---------------------- */

/* -- media query -- */

@media only screen and (max-width: 768px) {
  .feature_ProcessLevel_content {
    flex-direction: column;
  }

  .feature_ProcessLevel_content .featureLevel_List::before,
  .feature_ProcessLevel_content .featureLevel_List::after {
    width: 71%;
    margin: 13% 0;
    rotate: 90deg;
    left: 15%;
    top: unset;
  }

  .feature_ProcessLevel_content .featureLevel_List {
    text-align: center;
    margin: 60px 15px 0 15px;
    background: #212331;
    padding: 0;
  }
  .featureLevel_Head {
    margin: 0;
  }

  .socialMedia {
    width: 40% !important;
  }
  .socialMedia svg {
    font-size: 30px;
    margin: 0 5px;
  }
  .map-loc2 {
    padding-bottom: 50px;
  }
  .pricing_content_div {
    margin: 10px 0;
  }
  .walkThrough_header h3 {
    padding: unset;
  }
  .feature_slide_ques {
    margin: 0 20px;
  }
  .feature_slideContent_p {
    padding: unset;
  }
  .pricing_content_div {
    min-height: auto;
    max-height: unset;
  }
  .priceBtn {
    position: unset;
    transform: unset;
  }
  .pricing_content_div ul li {
    font-size: 14px;
    text-align: left;
  }
  .walkThroughPage_Backdrop {
    height: unset !important;
    padding-top: 35px;
  }
  .walkThroughPage {
    min-height: unset;
  }
}
@media only screen and (max-width: 991px) {
  .sidebarMenuBtn {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .pricing_content_div {
    min-height: auto;
    max-height: unset;
    /* min-height: 110vh;
    max-height: 110vh; */
  }
  .priceBtn {
    position: unset;
    transform: unset;
  }
  .navSection.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.5rem 0.4rem !important;
  }
  .sidebarMenuBtn {
    top: 5%;
  }
}
@media only screen and (min-width: 1280px) {
  .sidebarMenuBtn {
    top: 17% !important;
    right: 2% !important;
  }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
  .pricing_content_div ul li > span {
    padding-left: 0px;
    font-size: 13px;
  }
  .pricing_content_div p {
    font-size: 12px;
  }
}
