

.footerDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}
.socialMedia{
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.socialMedia svg{
    font-size: 25px;
    color: #676767;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
}
.copyright{
    color: #a5a5a5;
    font-size: 14px;
}
.socialMedia svg:hover{
    scale: 1.3;
}